<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app>
        <v-app-bar
                fix
                app
                color="dark"
                dark
                class="z-index-top"
        >
            <div class="d-flex align-center">

                <a href="/" target="_blank">
                    <v-img
                            alt="Vuetify Logo"
                            contain
                            src="@/assets/valley_logo.png"
                            transition="scale-transition"
                            width="60"
                    />
                </a>
                <a href="/" target="_blank">
                    <v-img
                            alt="Vuetify Name"
                            contain
                            min-width="100"
                            src="@/assets/valley_logo_text.png"
                            width="150"
                    />
                </a>
            </div>

            <nav class="navbar navbar-static-top hidden-md-and-down" style="margin:35px auto 0 auto">
                <div class="navbar-custom2-menu">
                    <ul class="navbar-nav1 bottomMenu" style="margin:0 150px;">
                        <li class="list"><a href="/admin/mainPage">메인관리</a></li>
                        <li class="list"><a href="/admin/main">기본설정</a></li>
                        <li class="list"><a href="/admin/room/list">객실관리</a></li>
                        <li class="list"><a href="/admin/pop/list">기타관리</a></li>
                        <li class="list"><a href="/admin/resv/list">예약관리</a></li>
                        <li class="list"><a href="/admin/stat/desc/price">예약통계</a></li>
                        <li class="list"><a href="/admin/acc/day">접속통계</a></li>

                    </ul>
                </div>
            </nav>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                                class="shrink mt-1 hidden-lg-and-up"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer
                v-model="drawer"
                temporary
                :fixed="true"
                :width="325"
                :touchless="true"
                right
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        Application
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        subtext
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list>
                <template v-for="(item, i) in items">
                    <!-- if it's a menu item with no children -->
                    <v-list-item
                            color="primary"
                            v-if="!item.subItem"
                            :key="i"
                            :to="item.route"
                            @click="log"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- else if it has children -->
                    <v-list-group
                            v-else
                            :key="item.title"
                            v-model="item.active"
                            :prepend-icon="item.icon"
                            no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                                v-for="subItem in item.subItem"
                                :key="subItem.title"
                                :to="subItem.route"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="subItem.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-main class="admin-body">
            <router-view/>
        </v-main>

        <v-footer
                dark
                padless
        >
            <v-container>
                <v-row justify="center" class="mt-2">
                    <v-col class="text-center">
                        COPYRIGHT(C)2023 VALLEY1.CO.KR ALL RIGHTS RESERVED
                    </v-col>
                    <v-btn
                            v-if="isLogin"
                            :key="item.title"
                            class="mx-4 white--text"
                            @click="logout()">
                        관리자 로그아웃
                    </v-btn>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>

    export default {

        name: 'HomeLayout',
        data: () => ({
            drawer: false,
            group: null,
            item: 1,
            items: [
                {
                    title: '메인', icon: 'mdi-star', route: '/admin/mainPage',
                },
                {
                    title: '기본설정', icon: 'mdi-view-dashboard', route: '/admin/main',
                },
                {
                    title: '객실관리', icon: 'mdi-view-dashboard', route: '/admin/room/list',
                },
                {
                    title: '기타관리', icon: 'mdi-view-dashboard', route: '/admin/pop/list',
                },
                {
                    title: '예약관리', icon: 'mdi-view-dashboard', route: '/admin/resv/list',
                },
                {
                    title: '예약통계', icon: 'mdi-view-dashboard', route: '/admin/stat/desc/price',
                },
                {
                    title: '접속통계', icon: 'mdi-view-dashboard', route: '/admin/acc/day',
                },
            ],
            right: null,
            isLogin: false,

        }),
        created: function () {

            var path = window.location.pathname;
            if(path === '/admin/login'){

            }else {
                this.$isAuthCheck(this.$getCookie("AUTH"));
                if (this.$getCookie("AUTH") === 'true') {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                }
            }



        },
        methods: {
            log() {
            },
            logout() {
                this.$deleteCookie("AUTH");
                //this.$router.push({path: "/admin/login"});
                this.$store.commit("admin/LOGOUT");

                location.href = '/admin/login';
            },
        },

        watch: {
            group() {
                this.drawer = false
            },

        },
    };

    $(function () {

    });
</script>

<style>

</style>
